
import { AnonymousSubscription } from 'rxjs/Subscription';
import { timer } from 'rxjs';
import { Constructor } from 'orv-ui-base-lib';


export function DataRefresh<DataRefreshBase extends Constructor>(Base: DataRefreshBase) {

    class Mixin extends Base {
        private timerSubscription: AnonymousSubscription;
        private dataSubscription: AnonymousSubscription;

        autorefresh: boolean = true;
        autorefreshtime: number = 5000;

        lastUpdate = new Date();
        loading: boolean;

        init(interval: number) {
            this.autorefreshtime = interval;
        }

        destroy(): void {
            if (this.dataSubscription) {
                this.dataSubscription.unsubscribe();
            }
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
        }


        cancelRefresh() {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
        }

        refreshData() {
            delete this.lastUpdate;
            this.lastUpdate = new Date();
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
            if (this.autorefresh) {
                this.subscribeToData();
            }
        }

        private subscribeToData(): void {
            this.timerSubscription = timer(this.autorefreshtime).subscribe(() => this.refreshData());
        }
    }

    return Mixin;
}
